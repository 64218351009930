import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';

import { buildSingleDataSets } from '../util/customData';

import CustomVisualData from './CustomVisualData';
import CustomKey from './CustomKey';
import CustomLegend from './CustomLegend';

import { zeroWhy, uiBarOptions, uiDoughnutOptions, uiPieOptions, uiPolarOptions } from '../util/customOptions';
import { Bar, Doughnut, Polar, HorizontalBar, Pie, Line } from 'react-chartjs-2';
import * as Chart from 'chart.js';

import '../styles/styles.scss';

interface Props extends PanelProps<SimpleOptions> {}

export const CustomSingle: React.FC<Props> = (props) => {
  const { options, data, width, height } = props;
  const uiData = buildSingleDataSets(data, options);
  Chart.Tooltip.positioners.zeroWhy = zeroWhy;

  const renderChart = (uiData: {}) => {
    switch (options.type) {
      case 'Line':
        return <Line data={uiData} options={uiBarOptions} />;
      case 'Polar':
        return <Polar data={uiData} options={uiPolarOptions} />;
      case 'Pie':
        return <Doughnut data={uiData} options={uiPieOptions(options.valueMode)} />;
      case 'Gauge':
        return <Pie data={uiData} options={uiDoughnutOptions} />;
      case 'HorizontalBar':
        return <HorizontalBar data={uiData} options={uiBarOptions} />;
      case 'Bar':
        return <Bar data={uiData} options={uiBarOptions} />;
      default:
        return <CustomVisualData data={data} />;
    }
  };

  return (
    <div className="rradar-single">
      <div className="chart-wrapper">
        <div
          className="chart"
          style={{ width: options.showKey ? '60%' : width, height: options.showLegend ? height - 60 : height }}
        >
          {renderChart(uiData)}
        </div>
        {options.type !== 'Data' && options.showKey && <CustomKey uiData={uiData} options={options} />}
      </div>
      {options.type !== 'Data' && options.showLegend && <CustomLegend uiData={uiData} options={options} />}
    </div>
  );
};

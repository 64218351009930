import React from 'react';

import { getColor } from '../util/customColors';
import { SimpleOptions } from '../types';

interface Props {
  uiData: any;
  options: SimpleOptions;
}

export class CustomLegend extends React.Component<Props> {
  render() {
    return (
      <div className="chart-legend">
        {this.props.uiData.labels && (
          <span className="chart-legend__items">
            {this.props.uiData.labels.map((item: any, i: number) => {
              return (
                <span
                  key={i}
                  className="chart-legend__item"
                  style={{ color: getColor(i, this.props.options.colorPalette) }}
                >
                  {' '}
                  {item} <strong className="chart-legend__value">{this.props.uiData.datasets[0].data[i]}</strong>{' '}
                </span>
              );
            })}
          </span>
        )}
        {this.props.uiData.master && (
          <span className="chart-legend__master">
            {this.props.uiData.master.label} : <strong>{this.props.uiData.master.amt}</strong>
          </span>
        )}
      </div>
    );
  }
}

export default CustomLegend;

import { ChartOptions, ChartLegendOptions, ChartTooltipOptions } from 'chart.js';

export const zeroWhy = (elements: any[]) => {
  let x = 0;
  elements.forEach((item) => {
    if (item && item.hasValue()) {
      x += item.tooltipPosition().x;
    }
  });
  return {
    x: x / elements.length,
    y: 0,
  };
};

const tooltips: ChartTooltipOptions = {
  backgroundColor: '#060606',
  bodyFontColor: '#bbb',
  bodyFontSize: 13,
  bodySpacing: 5,
  borderColor: '#444',
  borderWidth: 1,
  caretSize: 0,
  filter: (item: any) => item.value > 0,
  intersect: false,
  mode: 'index',
  multiKeyBackground: '#000',
  position: 'zeroWhy',
  titleFontSize: 13,
  titleSpacing: 5,
};

const noLegend: ChartLegendOptions = {
  display: false,
};

const scales = {
  xAxes: [
    {
      gridLines: {
        color: 'rgba(128,128,128,0.3)',
      },
      stacked: false,
      ticks: {
        min: 0,
      },
    },
  ],
  yAxes: [
    {
      gridLines: {
        color: 'rgba(128,128,128,0.3)',
      },
      stacked: false,
      ticks: {
        min: 0,
      },
    },
  ],
};

export const uiBarOptions: ChartOptions = {
  legend: noLegend,
  maintainAspectRatio: false,
  scales,
  tooltips,
};

export const uiDoughnutOptions: ChartOptions = {
  circumference: 1.2 * Math.PI,
  cutoutPercentage: 45,
  legend: noLegend,
  maintainAspectRatio: false,
  rotation: 0.9 * Math.PI,
};

export const uiPieOptions = (displayMode: string): ChartOptions => {
  const options = {
    cutoutPercentage: 0,
    legend: noLegend,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          const id = tooltipItem['index'];
          const label =
            `${data['labels'][id]}` +
            (displayMode !== 'percent' ? `: ${data['datasets'][0]['data'][id]}` : '') +
            (displayMode !== 'value' ? `: ${data['datasets'][0]['percents'][id]}%` : '');
          return label;
        },
      },
    },
  };

  return options;
};

export const uiPolarOptions: ChartOptions = {
  legend: noLegend,
  maintainAspectRatio: false,
  scale: {
    gridLines: {
      color: 'rgba(128,128,128,0.3)',
    },
    ticks: {
      display: false,
    },
  },
};

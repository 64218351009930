import React from 'react';

import { getColor } from '../util/customColors';
import { SimpleOptions } from '../types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp as sortUp } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleUp';
import { faArrowAltCircleDown as sortDown } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleDown';
import { faSort as notSorted } from '@fortawesome/free-solid-svg-icons/faSort';

interface Props {
  uiData: any;
  options: SimpleOptions;
}

interface TableData {
  color: string;
  description: string;
  value: number;
}

export class CustomKey extends React.Component<Props> {
  state = {
    sortDirection: 'default',
    sortMode: 'default',
  };

  private renderSortIcon = (key: string) => {
    const isSorted = key === this.state.sortMode;
    const icon = isSorted ? (this.state.sortDirection === 'desc' ? sortUp : sortDown) : notSorted;
    return (
      <span className={`chart-key__sorter ${isSorted ? ' active' : ''}`}>
        <FontAwesomeIcon icon={icon} size="1x" fixedWidth={true} />
      </span>
    );
  };

  render() {
    const tableData: TableData[] = this.buildTableRows();

    return (
      <div className="chart-key">
        <table>
          <thead>
            <tr>
              <th />
              <th>
                <span onClick={() => this.updateSort('key')}>{this.renderSortIcon('key')}</span>
              </th>
              {this.props.options.valueMode !== 'percent' && (
                <th>
                  <span onClick={() => this.updateSort('value')}>{this.renderSortIcon('value')}</span>
                </th>
              )}
              {this.props.options.valueMode !== 'value' && (
                <th>
                  <span onClick={() => this.updateSort('percent')}>{this.renderSortIcon('percent')}</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {tableData.map((item: any, i: number) => {
              return (
                <tr key={i}>
                  <td className="chart-key__swatch" style={{ backgroundColor: item.color }} />
                  <td className="chart-key__label">{item.description}</td>
                  {this.props.options.valueMode !== 'percent' && (
                    <td className="chart-key__value">
                      <strong>{item.value}</strong>
                    </td>
                  )}
                  {this.props.options.valueMode !== 'value' && (
                    <td className="chart-key__value">
                      <strong>{item.percent}%</strong>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  private updateSort = (mode: string) => {
    let newMode = 'default';
    let newDirection = 'default';
    if (this.state.sortMode !== mode) {
      newMode = mode;
      newDirection = 'desc';
    } else {
      if (this.state.sortDirection === 'desc') {
        newMode = mode;
        newDirection = 'asc';
      }
    }

    this.setState({
      sortDirection: newDirection,
      sortMode: newMode,
    });
  };

  private buildTableRows = (): TableData[] => {
    return this.applySort(
      this.props.uiData.labels.map((item: any, i: number) => {
        return {
          color: getColor(i, this.props.options.colorPalette),
          description: item,
          value: this.props.uiData.datasets[0].data[i],
          percent: this.props.uiData.datasets[0].percents[i],
        };
      })
    );
  };

  private applySort = (rows: TableData[]): TableData[] => {
    if (this.state.sortMode !== 'default') {
      rows.sort((a: TableData, b: TableData) =>
        this.getSortKey(a) > this.getSortKey(b)
          ? this.state.sortDirection === 'asc'
            ? 1
            : -1
          : this.state.sortDirection === 'asc'
          ? -1
          : 1
      );
    }
    return rows;
  };

  private getSortKey(item: TableData): string | number {
    return this.state.sortMode === 'key' ? item.description : item.value;
  }
}

export default CustomKey;

import { SimpleOptions } from '../types';
import { PanelData } from '@grafana/data';
import { Data } from './customSettings';
import { getColor } from './customColors';

// Convert Data in Table Mode (either multiple Query or Aggregate Query)
// series(x).fields(y).values(z) => x Queries, with y Columns and z Rows.
//
export const buildSingleDataSets = (data: PanelData, options: SimpleOptions) => {
  const { series } = data;

  if (series.length === 0) {
    return {};
  }

  // a. Convert Grafana data into Rowdata
  const rowData: Data[] =
    series[0].fields.length === 1
      ? multipleQueries(data, options.order, options.master)
      : singleAggQuery(data, options.order, options.master);

  const total = rowData.reduce((t, item) => t + item.amt, 0);
  // rowData.forEach(item => (item.percent = Math.round((item.amt / total) * 10000) / 100));

  // b. build Chart.js data from rowData
  return {
    datasets: [
      {
        backgroundColor: rowData.map((item, i) => getColor(i, options.colorPalette)),
        borderColor: rowData.map((item, i) => getColor(i, options.colorPalette)),
        borderWidth: 1,
        fill: false,
        data: rowData.map((item) => (item.isMaster ? getMasterAmt(rowData) - getRemainderAmt(rowData) : item.amt)),
        percents: rowData.map((item) => (item.percent = Math.round((item.amt / total) * 10000) / 100)),
      },
    ],
    labels: rowData.map((item) => (item.isMaster ? 'Others' : item.label)),
    master: rowData.find((item) => item.isMaster),
  };
};

// 1. Multiple Query Method => Single Dataset Required
// A.  select count as 'All fruit' from fruit
// B.  select count as 'Apple' from fruit where fruit_type = 'Apple'
// C.  select count as 'Orange' from fruit where fruit_type = 'Orange'
//
const multipleQueries = (data: PanelData, order: string, master: string) => {
  const { series } = data;
  return series
    .map((item, i) => {
      return {
        amt: item.fields[0].values.toArray()[0],
        isMaster: item.refId === master,
        label: item.fields[0].name,
        ref: item.refId,
        sort: getSortOrder(order, String(item.refId), i),
      };
    })
    .sort((a: Data, b: Data) => a.sort - b.sort);
};

// 2. Single Agg Query Method => Single Dataset Required
// A.  select fruit_type, count from fruit group by 1
//
const singleAggQuery = (data: PanelData, order: string, master: string) => {
  const { series } = data;
  return series[0].fields[0].values
    .toArray()
    .map((item, i) => {
      return {
        amt: series[0].fields[series[0].fields.length - 1].values.toArray()[i],
        isMaster: item === master,
        label: item,
        ref: item,
        sort: getSortOrder(order, item, i),
      };
    })
    .sort((a: Data, b: Data) => a.sort - b.sort);
};

const getSortOrder = (order: string, key: string, index: number) => {
  return order ? (order.indexOf(String(key)) === -1 ? 99999 : order.indexOf(String(key))) : index;
};

export const getMaster = (data: Data[]) => {
  return data.find((i) => i.isMaster);
};

export const getMasterAmt = (data: Data[]) => {
  const master = getMaster(data);
  return master ? master.amt : 0;
};

export const getRemainderAmt = (data: Data[]) => {
  return data.reduce((a, b) => a + (b.isMaster ? 0 : b.amt), 0);
};
